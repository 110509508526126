<template>
  <div class="top-body">
    <div class="top-left">
      <div class="top-left-title">
        <h6>平均震级</h6>
        <span
          ><b>{{ zhenji }}</b
          >级</span
        >
        <img src="../../../../public/img/pictre.png" alt="" />
      </div>
    </div>
    <div class="top-right">
      <div class="top-left-title">
        <h6>全部死亡人数</h6>
        <span
          ><b>{{ dp }}</b
          >人</span
        >
        <img src="../../../../public/img/pictre.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { deathAll, magDivide1 } from "../../../axios/api/LargeScreen";
export default {
  name: "EChartsDemo",
  data() {
    return {
      zhenji: "",
      dp: "",
    };
  },
  mounted() {
    this.DataList();
  },
  methods: {
    async DataList() {
      await magDivide1()
        .then((res) => {
          this.zhenji = parseFloat(res.data.toFixed(1));
        })
        .catch((err) => {});
      await deathAll()
        .then((res) => {
          this.dp = res.data;
        })
        .catch((err) => {});
    },
  },
};
</script>

<style scoped>
.top-body {
  width: 100%;
  height: calc(100% - 30px);
}
.top-body .top-left {
  float: left;
  width: 50%;
  height: 100%;
}
.top-left-title {
  width: 100%;
  height: 100%;
  padding: 30px;
  box-sizing: border-box;
}
.top-left-title h6 {
  color: #bad0e2;
}
.top-left-title span {
  margin-top: 30px;
  display: inline-block;
  color: #2aa4f1;

  /*    height: 80px;
    line-height: 80px;*/
}
.top-left-title span b {
  font-size: 30px;
  letter-spacing: 5px;
}
.top-left-title img {
  width: 80%;
}
.top-body .top-right {
  float: left;
  width: 50%;
  height: 100%;
}
</style>
