<template>
  <div id="echart_china" ref="echart_china"></div>

  <!-- <div> 111</div> -->
</template>

<script>
import * as echarts from "echarts";
import { magtop9 } from "../../../axios/api/LargeScreen";
const chinaJson = require("../../../../public/china.json"); //获取中国地图的json数据

export default {

  mounted() {
    this.initChart();
    this.changeData();
  },
  methods: {
    async changeData() {

      // 初始化 ECharts 实例
      const myChart = echarts.init(this.$refs.echart_china);

      // 注册中国地图
      this.$echarts.registerMap("china", {geoJSON: chinaJson});
      let data = [];
      await magtop9().then((res) => {

        let LableData = [];
        LableData = [];
        res.data[0].positon=[99, 41.55, 100];
        res.data[1].positon=[80.24, 18.55, 100];
        res.data[2].positon=[89, 45, 100];
        res.data[3].positon= [120.24, 46.55, 100];
        res.data[4].positon=[125.24, 10.55, 100];
        console.log(res);
        res.data.forEach((item) => {

          data.push([
            {
              name: item.earthquake_name,
              value: [item.longitude, item.latitude],
            },
          ])
          LableData.push(
              {
                name: item.earthquake_name+"("+item.earthquake_time.substring(0, 4)+"年)",
                coords: [
                  [item.longitude, item.latitude, 100],
                  item.positon,
                ], // 线条位置[开始位置，结束位置]
                value: [item.death,item.magnitude ]
              },
          )
        });
        console.log(LableData, "LableData");
        const option = {
          tooltip: {
            trigger: "item",
            formatter: "{b}",
          },
          geo: {
            map: "china",
            aspectScale: 0.85,
            layoutCenter: ["50%", "50%"], //地图位置
            layoutSize: "100%",
            itemStyle: {
              normal: {
                shadowColor: "#276fce",
                shadowOffsetX: 0,
                shadowOffsetY: 15,
                opacity: 0.5,
              },
              emphasis: {
                areaColor: "#276fce",
              },
            },
            regions: [
              {
                name: "南海诸岛",
                itemStyle: {
                  areaColor: "rgba(0, 10, 52, 1)",

                  borderColor: "rgba(0, 10, 52, 1)",
                  normal: {
                    opacity: 0,
                    label: {
                      show: false,
                      color: "#009cc9",
                    },
                  },
                },
                label: {
                  show: false,
                  color: "#FFFFFF",
                  fontSize: 12,
                },
              },
            ],
          },
          series: [
            // 常规地图
            {
              type: "map",
              mapType: "china",
              aspectScale: 0.85,
              layoutCenter: ["50%", "50%"], //地图位置
              layoutSize: "100%",
              zoom: 1, //当前视角的缩放比例
              // roam: true, //是否开启平游或缩放
              scaleLimit: {
                //滚轮缩放的极限控制
                min: 1,
                max: 2,
              },
              itemStyle: {
                normal: {
                  areaColor: "#0c274b",
                  borderColor: "#1cccff",
                  borderWidth: 1.5,
                },
                emphasis: {
                  areaColor: "#02102b",
                  label: {
                    color: "#fff",
                  },
                },
              },
            },
            {
              //首都星图标
              name: "首都",
              type: "scatter",
              coordinateSystem: "geo",
              data: [
                {
                  name: "首都",
                  value: [116.24, 41.55, 100],
                },
              ],
              // symbol: iconRQ,
              symbolSize: 20,
              label: {
                normal: {
                  show: false,
                },
                emphasis: {
                  show: false,
                },
              },
            },
            // 区域散点图
            {
              type: "effectScatter",
              coordinateSystem: "geo",
              zlevel: 2,
              symbolSize: 10,
              rippleEffect: {
                //坐标点动画
                period: 3,
                scale: 5,
                brushType: "fill",
              },
              label: {
                normal: {
                  show: true,
                  position: "right",
                  formatter: "{b}",
                  color: "#b3e2f2",
                  fontWeight: "bold",
                  fontSize: 16,
                },
              },
              data: data,
              itemStyle: {
                //坐标点颜色
                normal: {
                  show: true,
                  color: "#ff8003",
                  shadowBlur: 20,
                  shadowColor: "#fff",
                },
                emphasis: {
                  areaColor: "#f00",
                },
              },
            },
            {
              type: "lines",
              zlevel: 3,
              symbol: "circle",
              symbolSize: [5, 5],
              color: "#ff8003",
              opacity: 1,
              label: {
                show: true,
                padding: [10, 20],
                color: "#fff",
                backgroundColor: "#1a3961",
                borderColor: "#aee9fb",
                borderWidth: 1,
                borderRadius: 6,
                formatter(params) {
                  let arr = [
                    params.name,
                    "最高震级：" + params.value[1] + "级",
                    "累计伤亡人数：" + params.value[0] + "人",
                  ];
                  return arr.join("\n");
                },
                textStyle: {
                  align: "left",
                  lineHeight: 20,
                },
              },
              lineStyle: {
                type: "solid",
                color: "#fff",
                width: 0.5,
                opacity: 1,
              },
              data: LableData,
            },
          ],
        };

        // 将选项设置到 ECharts 实例中
        myChart.setOption(option);

      });
    },

    initChart() {

      let data = [];
      let LableData = [];

    },
  }
};
</script>

<style scoped>
#echart_china {
  padding-top: 70px;
  width: 100%;
  height: 100%;
  /* background-color: pink; */
}
</style>
