<template>
  <div class="homeClass flex">
    <div class="navClass">
      <NavDemo title="全国震害与救援视界" style="height: 100%" />
    </div>
    <div class="leftBox">
      <div class="Magnitude">
        <div class="title"> <span>  </span> 2013-2023地震数量</div>
        <Magnitude />
      </div>
      <div class="distribution">
        <div class="title"> <span>  </span> 2000-2023地震频繁发生地区排行榜</div>
        <distributionLeidatu />
      </div>
      <div class="death">
        <div class="title"> <span>  </span> 2000-2023地震死亡人数地区排行榜</div>
        <deathBarCharts />
      </div>
    </div>
    <div class="centerBox flex-1">
      <mapCharts/>
    </div>
    <div class="rightBox">
      <div class="earthquake">
        <div class="title"> <span>  </span> 2000-2023地震 Top5</div>
        <earthquakeTable />
      </div>
      <div class="percentage">
        <div class="title"> <span>  </span> 2000-2023年重大地震排行榜</div>
      <percentagePieCharts />
      </div>
      <div class="grade">
        <div class="title"> <span>  </span> 2016-2020震级折线图</div>
        <gradeLineCharts />
      </div>
    </div>
  </div>
</template>

<script>
import NavDemo from "@/components/FrmHeader.vue";
import gradeLineCharts from "@/views/examples/echarts/gradeLineCharts.vue";
import percentagePieCharts from "@/views/examples/echarts/percentagePieCharts.vue";
import earthquakeTable from "@/views/examples/echarts/earthquakeTable.vue";
import deathBarCharts from "@/views/examples/echarts/deathBarCharts.vue";
import distributionLeidatu from "@/views/examples/echarts/distributionLeidatu.vue";
import Magnitude from "@/views/examples/echarts/MagnitudeTable.vue";
import mapCharts from "@/views/examples/echarts/mapCharts.vue";

export default {
  components:{
    NavDemo,
    gradeLineCharts,
    percentagePieCharts,
    earthquakeTable,
    deathBarCharts,
    distributionLeidatu,
    Magnitude,
    mapCharts
  }
};
</script>
<style lang="scss" scoped>
.vue-grid-item {
  /* border: solid #86f2ff 1px; */
  /* box-shadow: 0 0 15px 3px #86f2ff inset; */
  /* border-radius: 7px; */
  /* background-color: #00000066; */
}

.homeClass {
  height: 100%;
  background: url('../../public/img/stars.jpg');
  background-size: 100% 100%;
  background-color: #0d325f;

  .navClass {
    position: fixed;
    top: 0;
    height: 10%;
    width: 100%;
    z-index: 5;
  }

  .staticClass {
    position: relative;
    height: 100%;
  }

  .moveClass {
    position: absolute;
    height: 90%;
    width: 100%;
    pointer-events: none;
    top: 10%;
    overflow: auto;

    .demoClass {
      pointer-events: auto;
      height: 35%;
      width: 20%;
      position: absolute;
    }
  }
}
.leftBox{
  margin-left: 10px;
  height: 100%;
  width: 21%;
  // background: pink;
  padding-top: 90px;
  .Magnitude{
    height: 200px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);;
    padding:0 15px;
    margin-bottom: 15px;
    .title{
      color: #fff;
      // line-height: -5px;
      span{
        display: inline-block;
        width: 5px;
        height: 25px;
        background-color: #2997e4;
      }
    }
  }
  .distribution{
    width: 100%;
    height: 350px;
    padding:0 15px;
    background-color: rgba(0, 0, 0, 0.5);;
    margin-bottom: 15px;

    .title{
      // line-height: -5px;
      color: #fff;
      span{
        display: inline-block;
        width: 5px;
        height: 25px;
        background-color: #2997e4;
      }
    }
  }
  .death{
    height: 240px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);;
    // background-color: pink;
    padding:0 15px;
    margin-bottom: 15px;
    .title{
      color: #fff;
      // line-height: -5px;
      span{
        display: inline-block;
        width: 5px;
        height: 25px;
        background-color: #2997e4;
      }
    }
  }

}
.centerBox{
  height: 100%;
  width: 56%;
  // background: yellow;
}
.rightBox{
  height: 100%;
  width: 22%;
  // background: pink;
  padding-top: 90px;
  .earthquake{
    height: 300px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);;
    padding:0 15px;
    margin-bottom: 15px;
    .title{
      color: #fff;
      // line-height: -5px;
      span{
        display: inline-block;
        width: 5px;
        height: 25px;
        background-color: #2997e4;
      }
    }
  }
  .percentage{
    height: 230px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);;
    // background-color: pink;
    padding:0 15px;
    margin-bottom: 15px;
    .title{
      color: #fff;
      // line-height: -5px;
      span{
        display: inline-block;
        width: 5px;
        height: 25px;
        background-color: #2997e4;
      }
    }
  }
  .grade{
    height: 260px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);;
    padding:0 15px;
    margin-bottom: 15px;
    .title{
      color: #fff;
      // line-height: -5px;
      span{
        display: inline-block;
        width: 5px;
        height: 25px;
        background-color: #2997e4;
      }
    }}
  }

</style>
