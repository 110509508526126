<template>
  <div class="title-nav">
    <div class="top5-ul">
      <ul v-for="(item, index) in datalist" :key="index">
        <li>
          <span>{{ index + 1 }}</span>
          <span>{{ item.earthquakeName }}</span>
          <span>{{ "("+item.earthquakeTime.substring(0, 4)+"年)"}}</span>
          <span>{{ item.magnitude }}</span>
          <!-- <span>15公里</span> -->
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { magtop8 } from "../../../axios/api/LargeScreen";
export default {
  name: "EChartsDemo",
  data() {
    return {
      datalist: [],
    };
  },
  mounted() {
    this.DataList();
  },
  methods: {
    async DataList() {
      await magtop8()
        .then((res) => {
          this.datalist = res.data;
        })
        .catch((err) => {});
      console.log(this.datalist, "09");
    },
  },
};
</script>

<style scoped>
.title-nav {
  width: 100%;
  height: calc(100% - 30px);
}
.title-nav .top5-ul {
  width: calc(100% - 20px);
  height: calc(100% - 30px);
  padding: 10px;
}
.title-nav .top5-ul ul {
  width: 100%;
  height: 20%;
}
.title-nav .top5-ul ul,
li {
  list-style: none;
}
.title-nav .top5-ul ul > li {
  width: 100%;
  /* height: 20%; */
  color: #ffffff;
  /* line-height: 68px; */
  justify-content: center;
}
.title-nav .top5-ul ul li span {
  margin-left: 3%;
  font-size: 14px;
}
.title-nav .top5-ul ul li span:nth-child(1) {
  color: #eb6841;
  font-style: oblique;
  /*width: 10%;*/
  /*#20a8fe
          #EB6841
          #3FB8AF
          #FE4365
          #FC9D9A*/
  display: inline-block;
  text-align: center;
  font-size: 20px;
}

/* .title-nav .top5-ul ul li span:nth-child(2) {
  width: 10%;
  display: inline-block;
  text-align: center;

  height: 30px;
  line-height: 30px;
  
  vertical-align: center;
  border-radius: 5px;
  color: #ffffff;
} */
/* .title-nav .top5-ul ul li:nth-child(1) span:nth-child(2),
.title-nav .top5-ul ul li:nth-child(2) span:nth-child(2),
.title-nav .top5-ul ul li:nth-child(3) span:nth-child(2) {
  background: #d89346;
}
.title-nav .top5-ul ul li:nth-child(4) span:nth-child(2),
.title-nav .top5-ul ul li:nth-child(5) span:nth-child(2) {
  background: #1db5ea;
} */
.title-nav .top5-ul ul li span:nth-child(3) {
  /*width: 15%;*/
  display: inline-block;
  text-align: center;
}
.title-nav .top5-ul ul li span:nth-child(4) {
  /*width: 15%;*/
  display: inline-block;
  text-align: center;
}
.title-nav .top5-ul ul li span:nth-child(5) {
  /*width: 20%;*/
  display: inline-block;
  text-align: center;
}
.title-nav .top5-ul ul li span:nth-child(6) {
  display: inline-block;
  text-align: center;
} /* Add your CSS styles here */
</style>
