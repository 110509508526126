<template>
  <div id="death"></div>
</template>

<script>
import * as echarts from "echarts";
import { deathtop5 } from "../../../axios/api/LargeScreen";
export default {
  name: "EChartsDemo",
  data() {
    return {
      myChart: null,
      PvName: [],
      salvProValue: [],
      salvProMax: [], // 背景按最大值
      dataStyle: {
        normal: {
          label: {
            show: false,
          },
          labelLine: {
            show: false,
          },
          shadowBlur: 0,
          shadowColor: "#203665",
        },
      },
    };
  },
  computed: {
    option() {
      return {
        grid: {
          left: "2%",
          right: "2%",
          bottom: "-6%",
          top: "8%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "none",
          },
          formatter: (params) => {
            return params[0].name + " : " + params[0].value;
          },
        },
        xAxis: {
          show: false,
          type: "value",
        },
        yAxis: [
          {
            type: "category",
            inverse: true,
            axisLabel: {
              show: true,
              textStyle: {
                color: "#fff",
              },
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            data: this.PvName, // 此处修正
          },
          {
            type: "category",
            inverse: true,
            axisTick: "none",
            axisLine: "none",
            show: true,
            axisLabel: {
              textStyle: {
                color: "#ffffff",
                fontSize: "12",
              },
            },
            data: this.salvProValue, // 此处修正
          },
        ],
        series: [
          {
            name: "值",
            type: "bar",
            zlevel: 1,
            itemStyle: {
              normal: {
                barBorderRadius: 30,
                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  {
                    offset: 0,
                    color: "rgb(57,89,255,1)",
                  },
                  {
                    offset: 1,
                    color: "rgb(46,200,207,1)",
                  },
                ]),
              },
            },
            barWidth: 10,
            data: this.salvProValue, // 此处修正
          },
          {
            name: "背景",
            type: "bar",
            barWidth: 10,
            barGap: "-100%",
            data: this.salvProMax, // 此处修正
            itemStyle: {
              normal: {
                color: "rgba(24,31,68,1)",
                barBorderRadius: 30,
              },
            },
          },
        ],
      };
    },
  },
  mounted() {
    this.DataList();
    this.initChart();
  },
  methods: {
    async DataList() {
      await deathtop5()
        .then((res) => {
          res.data.forEach((e) => {
            this.PvName.push(e.name+"("+e.year+"年)");
            this.salvProValue.push(Math.round(e.death));
          });
          res.data.sort();
          console.log(this.PvName, this.salvProValue, 90);
        })
        .catch((err) => {});
      this.myChart.setOption(this.option);
    },
    initChart() {
      this.myChart = echarts.init(document.getElementById("death"));
      this.myChart.setOption(this.option);
    },
  },
};
</script>

<style scoped>
#death {
  width: 100%;
  height: 200px;
}
/* Add your CSS styles here */
</style>
