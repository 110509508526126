<template>
  <div id="distribution"></div>
</template>

<script>
import * as echarts from "echarts";
import  {frequent} from "../../../axios/api/LargeScreen";
export default {
  name: "EChartsDemo",
  data() {
    return {
      myChart: null,
      option: {
        color: ["#00c2ff", "#f9cf67", "#e92b77"],
        legend: {
          show: true,
          // icon: 'circle',//图例形状
          bottom: 0,
          center: 0,
          itemWidth: 14, // 图例标记的图形宽度。[ default: 25 ]
          itemHeight: 14, // 图例标记的图形高度。[ default: 14 ]
          itemGap: 21, // 图例每项之间的间隔。[ default: 10 ]横向布局时为水平间隔，纵向布局时为纵向间隔。
          textStyle: {
            fontSize: 12,
            color: "#ade3ff",
          },
          data: ["2021", "2022", "2023"],
        },
        radar: [
          {
            indicator: [
              {
                text: "西藏",
                max: 300,
              },
              {
                text: "台湾",
                max: 300,
              },
              {
                text: "青海",
                max: 300,
              },
              {
                text: "四川",
                max: 300,
              },
              {
                text: "新疆",
                max: 300,
              },
            ],
            textStyle: {
              color: "red",
            },
            center: ["50%", "50%"],
            radius: 100,
            startAngle: 90,
            splitNumber: 3,
            orient: "horizontal", // 图例列表的布局朝向,默认'horizontal'为横向,'vertical'为纵向.
            // shape: 'circle',
            // backgroundColor: {
            //     image:imgPath[0]
            // },
            name: {
              formatter: "{value}",
              textStyle: {
                fontSize: 12, //外圈标签字体大小
                color: "#5b81cb", //外圈标签字体颜色
              },
            },
            splitArea: {
              // 坐标轴在 grid 区域中的分隔区域，默认不显示。
              show: true,
              areaStyle: {
                // 分隔区域的样式设置。
                color: ["#141c42", "#141c42"], // 分隔区域颜色。分隔区域会按数组中颜色的顺序依次循环设置颜色。默认是一个深浅的间隔色。
              },
            },
            // axisLabel:{//展示刻度
            //     show: true
            // },
            axisLine: {
              //指向外圈文本的分隔线样式
              lineStyle: {
                color: "#153269",
              },
            },
            splitLine: {
              lineStyle: {
                color: "#113865", // 分隔线颜色
                width: 1, // 分隔线线宽
              },
            },
          },
        ],
        series: [
          {
            name: "雷达图",
            type: "radar",
            itemStyle: {
              emphasis: {
                lineStyle: {
                  width: 4,
                },
              },
            },
            data: [],
          },
        ],
      },
    };
  },
  mounted() {
    this.changedata();
    this.initChart();
  },
  methods: {
    changedata()
    {
      this.option.legend.data.forEach((i) => {
        frequent(i)
            .then((res) => {

             let valuelist = [];
             // valuelist=[85, 65, 55, 90, 82];
            valuelist=res.data.map(item => item.death);
            console.log(valuelist);
            var ave = [];
            ave["2021"]= "#00c2ff";
            ave["2022"]= "#f9cf67";
            ave["2023"]= "#e92b77";
            const j=i;
                this.option.series[0].data.push({
                  name: j,
                  value: valuelist, // 新的值
                  symbolSize: 2.5,
                  itemStyle: {
                    normal: {
                      borderColor: ave[j], // 新的边框颜色
                      borderWidth: 2.5,
                    },
                  },
                  areaStyle: {
                    normal: {
                      color: {
                        type: "linear",
                        x: 0,
                        y: 0,
                        x2: 1,
                        y2: 1,
                        colorStops: [
                          {
                            offset: 0,
                            color: ave[j],
                          },
                          {
                            offset: 0.5,
                            color: "rgba(0,0,0,0)",
                          },
                          {
                            offset: 1,
                            color: ave[j],
                          },
                        ],
                        globalCoord: false,
                      },
                      opacity: 1,
                      label: {
                        // 单个拐点文本的样式设置
                        normal: {
                          show: true, // 单个拐点文本的样式设置。[ default: false ]
                          position: "top", // 标签的位置。[ default: top ]
                          distance: 2, // 距离图形元素的距离。当 position 为字符描述值（如 'top'、'insideRight'）时候有效。[ default: 5 ]
                          color: "#6692e2", // 文字的颜色。如果设置为 'auto'，则为视觉映射得到的颜色，如系列色。[ default: "#fff" ]
                          fontSize: 14, // 文字的字体大小
                          formatter: function (params) {
                            return params.value;
                          },
                        },
                      },
                      itemStyle: {
                        normal: {
                          //图形悬浮效果
                          borderColor: ave[j],
                          borderWidth: 2.5,
                        },
                      },
                    },
                  },
                });
              this.myChart.setOption(this.option);
            })
      })
    },
    initChart() {
      this.myChart = echarts.init(document.getElementById("distribution"));

    },
  },
};
</script>
<style scoped>
#distribution {
  width: 100%;
  height: 300px;
}
/* Add your CSS styles here */
</style>
