<template>
  <div id="grade" v-if="option.series"></div>
</template>

<script>
import * as echarts from "echarts";
import { averDis } from "../../../axios/api/LargeScreen";
export default {
  name: "EChartsDemo",
  data() {
    return {
      myChart: null,
      aver: [],
      option: {
        color: ["#f0c725", "#0BE3FF", "#16f892", "#2c6cc4"],
        // title: {
        //   left: 'center',
        //   text: '流速统计', // 添加标题内容
        //   textStyle: {
        //     color: '#FFFFFF',
        //     fontSize: 14
        //   }
        // },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          x: "center",
          top: 25,
          textStyle: {
            color: "#c1cadf",
            fontSize: 14,
          },
        },
        grid: {
          left: "6%",
          right: "4%",
          top: "35%",
          bottom: "1%",
          containLabel: true,
        },
        toolbox: {
          show: true,
          orient: "vertical",
          x: "right",
          y: "center",
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: ["2016", "2017", "2018", "2019", "2020"],
            axisLine: {
              lineStyle: {
                color: "rgba(240,199,37,0.5)",
              },
            },
            axisLabel: {
              interval: 0,
              color: "#c1cadf",
              fontSize: 15,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "级",
            nameTextStyle: {
              color: "#c1cadf",
              align: "right",
              lineHeight: 10,
            },
            axisLine: {
              lineStyle: {
                color: "rgba(240,199,37,0.5)",
              },
            },
            axisLabel: {
              interval: 0,
              color: "#c1cadf",
              fontSize: 15,
            },
            splitLine: {
              show: false,
            },
          },
        ],
        series: [],
      },
    };
  },
  created() {
    this.DataList();
  },
  mounted() {
    this.initChart();
  },
  methods: {
    async DataList() {
      await averDis()
        .then((res) => {
          const aver = res.data;
          aver[0].color = "#f0c725";
          aver[1].color = "#0be3ff";
          aver[2].color = "#16f892";
          aver[3].color = "#2c6cc4";
          aver[4].color = "#ad75fe";
          aver.forEach((e) => {
            this.option.series.push({
              name: e.name,
              type: "line",
              smooth: true,
              symbolSize: 8,
              data: [
                e.aver2019,
                e.aver2020,
                e.aver2021,
                e.aver2017,
                e.aver2018,
              ],
              barWidth: "30%",
              itemStyle: {
                normal: {
                  color: e.color,
                },
              },
            });
          });

          console.log(this.option.series, "78");
          this.myChart.setOption(this.option);
        })
        .catch((err) => {});
    },
    initChart() {
      this.myChart = echarts.init(document.getElementById("grade"));
      //this.myChart.setOption(this.option);
    },
  },
};
</script>

<style scoped>
#grade {
  width: 100%;
  height: 220px;
}
/* Add your CSS styles here */
</style>
