<template>
  <div id="percentage"></div>
</template>

<script>
import * as echarts from "echarts";
import { zhanbi } from "../../../axios/api/LargeScreen";
export default {
  name: "EChartsDemo",
  data() {
    return {
      myChart: null,
      zhanbi: [],
      dataStyle: {
        normal: {
          label: {
            show: false,
          },
          labelLine: {
            show: false,
          },
          shadowBlur: 0,
          shadowColor: "#203665",
        },
      },
      option: {
        series: [
          {
            name: "第一个圆环",
            type: "pie",
            clockWise: false,
            radius: [45, 55],
            itemStyle: this.dataStyle, // 使用 this.dataStyle 引用 dataStyle
            hoverAnimation: false,
            center: ["15%", "60%"],
            data: [
              {
                value: 50,
                name: " ",
                label: {
                  normal: {
                    rich: {
                      a: {
                        color: "#3a7ad5",
                        align: "center",
                        fontSize: 14,
                        fontWeight: "bold",
                      },
                      b: {
                        color: "#fff",
                        align: "center",
                        fontSize: 12,
                      },
                    },
                    formatter: function (params) {
                      return (
                        "{b|" +
                        params.name +
                        "}\n\n" +
                        "{a|" +
                        params.value +
                        "%}"
                      );
                    },
                    position: "center",
                    show: true,
                    textStyle: {
                      fontSize: "12",
                      fontWeight: "normal",
                      color: "#fff",
                    },
                  },
                },
                itemStyle: {
                  normal: {
                    color: "#2c6cc4",
                    shadowColor: "#2c6cc4",
                    shadowBlur: 0,
                  },
                },
              },
              {
                value: 50,
                // name: 'invisible',
                itemStyle: {
                  normal: {
                    color: "#24375c",
                  },
                  emphasis: {
                    color: "#24375c",
                  },
                },
              },
            ],
          },
          {
            name: "第二个圆环",
            type: "pie",
            clockWise: false,
            radius: [45, 55],
            itemStyle: this.dataStyle, // 使用 this.dataStyle 引用 dataStyle
            hoverAnimation: false,
            center: ["50%", "60%"],
            data: [
              {
                value: 30,
                label: {
                  normal: {
                    rich: {
                      a: {
                        color: "#d03e93",
                        align: "center",
                        fontSize: 14,
                        fontWeight: "bold",
                      },
                      b: {
                        color: "#fff",
                        align: "center",
                        fontSize: 12,
                      },
                    },
                    formatter: function (params) {
                      return (
                        "{b|" +
                        params.name +
                        "}\n\n" +
                        "{a|" +
                        params.value +
                        "%}"
                      );
                    },
                    position: "center",
                    show: true,
                    textStyle: {
                      fontSize: "12",
                      fontWeight: "normal",
                      color: "#fff",
                    },
                  },
                },
                itemStyle: {
                  normal: {
                    color: "#ef45ac",
                    shadowColor: "#ef45ac",
                    shadowBlur: 0,
                  },
                },
              },
              {
                value: 70,
                // name: 'invisible',
                itemStyle: {
                  normal: {
                    color: "#412a4e",
                  },
                  emphasis: {
                    color: "#412a4e",
                  },
                },
              },
            ],
          },
          {
            name: "第三个圆环",
            type: "pie",
            clockWise: false,
            radius: [45, 55],
            itemStyle: this.dataStyle, // 使用 this.dataStyle 引用 dataStyle
            hoverAnimation: false,
            center: ["85%", "60%"],
            data: [
              {
                value: 20,
                label: {
                  normal: {
                    rich: {
                      a: {
                        color: "#603dd0",
                        align: "center",
                        fontSize: 14,
                        fontWeight: "bold",
                      },
                      b: {
                        color: "#fff",
                        align: "center",
                        fontSize: 12,
                      },
                    },
                    formatter: function (params) {
                      return (
                        "{b|" +
                        params.name +
                        "}\n\n" +
                        "{a|" +
                        params.value +
                        "%}"
                      );
                    },
                    position: "center",
                    show: true,
                    textStyle: {
                      fontSize: "12",
                      fontWeight: "normal",
                      color: "#fff",
                    },
                  },
                },
                itemStyle: {
                  normal: {
                    color: "#613fd1",
                    shadowColor: "#613fd1",
                    shadowBlur: 0,
                  },
                },
              },
              {
                value: 80,
                // name: 'invisible',
                itemStyle: {
                  normal: {
                    color: "#412a4e",
                  },
                  emphasis: {
                    color: "#412a4e",
                  },
                },
              },
            ],
          },
        ],
      },
    };
  },
  mounted() {
    this.DataList();
    this.initChart();
  },
  methods: {
    async DataList() {
      await zhanbi()
        .then((res) => {
          console.log(res.data, "123");
          this.option.series[0].data[0].value = Math.floor(res.data[1].value * 100);
          this.option.series[0].data[0].name = res.data[1].name;
          this.option.series[1].data[0].value = Math.floor(res.data[2].value * 100);
          this.option.series[1].data[0].name = res.data[2].name;
          this.option.series[2].data[0].value = Math.floor(res.data[0].value * 100);
          this.option.series[2].data[0].name = res.data[0].name;
          console.log(this.option.series, "45");
          this.myChart.setOption(this.option);
        })
        .catch((err) => {});
    },
    initChart() {
      this.myChart = echarts.init(document.getElementById("percentage"));
    },
  },
};
</script>

<style scoped>
#percentage {
  width: 100%;
  height: 170px;
}
/* Add your CSS styles here */
</style>
